import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"
import Carousel from "../components/Carousel"

const Phanton4K = ({ data, location }) => {

  const url = `https://speedcambrasil.com.br${location.pathname}`

  let carouselImages = []
  data.allCarouselPhantom.edges.forEach(element => {
    carouselImages.push({ img: getImage(element["node"]) })
  })

  return (
    <>
      <Metatags
        title="Speedcam Brasil - Phanton Flex 4k"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>Phanton Flex 4K</i>
        </h1>
        <div className="h-20" />
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1" />
          <div className="col-span-10">
            <Carousel picts={carouselImages} />
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl m-4">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <p>
              A <b>Phantom Flex4K</b> oferece baixo ruído e imagens 4K de alta
              resolução no formato super 35mm. As imagens full-size são
              disponibilizadas a 9.4 Gpx no formato Vine Raw. Com até 1000 fps a
              4K as imagens são compatíveis com os principais aplicativos de
              graduação de cores e edição de vídeo e suportam taxas de
              sincronização de som padrão.
            </p>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <div className="container mx-auto text-xl">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-3" />
          <div className="col-span-8">
            <div>
              <h1 className="my-2">Principais características:</h1>
              <ul className="list-disc list-inside">
                <li className="list-none list-inside">
                  Saídas de vídeo 3G-SDI
                  <ul className="list-disc list-inside">
                    <li>Dual-link 4K</li>
                    <li>Suporte para Dual-SDI</li>
                  </ul>
                </li>
                <li className="list-none list-inside">
                  Suportes de bateria disponíveis:
                  <ul className="list-disc list-inside">
                    <li>V-Lock</li>
                    <li>Gold mount</li>
                    <li>Hawk Woods Reel Power</li>
                  </ul>
                </li>
                <li className="list-none list-inside">
                  Sistema de menu na câmera
                </li>
                <li className="list-none list-inside">
                  Opções de controle remoto
                </li>
                <li className="list-none list-inside">
                  CineStation IV inclui Ethernet de 10 Gb
                </li>
                <li className="list-none list-inside">
                  Formato de arquivo Cine Raw
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="h-12" />
      <div className="container mx-auto text-center">
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-2" />
          <div className="col-span-8">
            <div className="m-auto">
              <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/Flex4Kmanual.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      manual
                    </div>
                  </a>
                </div>
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/All4Kspec.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      spectral response curve
                    </div>
                  </a>
                </div>
                <div className="btn-wrapper profile text-center col-span-4">
                  <a
                    href="./pdfs/Flex4KFlex4KGS.PDF"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div
                      className="rounded text-opacity-90 text-sc-gray bg-yellow-400 hover:bg-yellow-500 active:bg-yellow-500 focus:outline-none focus:ring focus:bg-yellow-300 px-2 py-2 mx-auto"
                      style={{ width: "200px" }}
                    >
                      mechanical drawing
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default Phanton4K

export const query = graphql`
  {
    allCarouselPhantom: allFile(
      filter: { relativePath: { regex: "/phantom/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1536)
          }
        }
      }
    }
  }
`
